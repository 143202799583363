<template>
  <div class="d-flex flex-column" dir="rtl">
    <div class="text-center f14-l17 df28-l40-l">{{activeStep+1}}/{{wizard.steps.length}}</div>
    <form class="formfield-round-border" method="GET" ref="form" :action="wizard.steps[wizard.steps.length-1].submit.href">
      <div v-for="(step,idx) in wizard.steps" :key="idx" :ref="`step` + idx" v-show="idx==activeStep" class="margin-top-20">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row">
            <div v-if="idx>0" class="f20-l20-b red pointer" @click="wizardSkip(-1)">&lt;</div>
            <div class="text-center f20-l20-b df30-l30-b flex-grow-1">{{step.title}}</div>
            <div v-if="step.skip" class="f16-l10 red pointer" @click="wizardSkip(1)">{{step.skip.label}}</div>
          </div>
          <div class="text-center f14-l17" v-html="step.description"></div>
          <div class="margin-top-20"></div>
          <FormFieldComponent v-for="(field,idx2) in step.form.fields" :key="idx2" :field="field" class="formfield-round-border" />
        </div>
        <ButtonComponent v-if="step.next" :caption="step.next.label" @click="wizardSkip(1)" @keyup.enter="wizardSkip(1)" class="margin-top-20" />
        <ButtonComponent v-if="step.submit" :caption="step.submit.label" @click="onSubmit()" @keyup.enter="onSubmit()" class="margin-top-20" />
      </div>
    </form>
  </div>
</template>
<script>
import {formSubmit} from '@/modules/configuration.js'
import FormFieldComponent from "@/components/form/FormFieldComponent";
import ButtonComponent from "@/components/form/ButtonComponent"
export default {
  components: {
    FormFieldComponent,
    ButtonComponent
  },
  props: {
    wizard: Object,
  },
  data() {
    return {
      activeStep: 0,
    }
  },
  methods: {
    wizardSkip(s) {
      this.activeStep += s;
    },
    onSubmit() {
      let form = this.$refs.form;
      formSubmit(this, form);
      this.$emit("close");
    },
  }

}
</script>
