<template>
  <div class="d-flex flex-column g-0 h100 " v-if="!loading">

    <ToolbarComponent v-if="!$route.meta.fullscreen" :initialData="data.header" />

    <div class="container-content flex-grow-1">
      <span class="container-content-inner">
        <router-view />
      </span>
    </div>

    <FooterComponent v-if="!$route.meta.fullscreen" :data="data.footer" />

    <PopupComponent :showClose="false" v-if="showPopup" @close="closePopup" :variant="popupVarient">
      <CartPopupComponent :url="popupUrl" @close="closePopup"></CartPopupComponent>
      <PopupRendererComponent :url="popupUrl" :content="popupData" @close="closePopup"></PopupRendererComponent>
    </PopupComponent>
    <PopupComponent :showClose="false" v-if="showSelectCity" @close="closePopup">
      <SelectCityPopup @close="closePopup"></SelectCityPopup>
    </PopupComponent>
    <PopupComponent :showClose="false" v-if="showSearchPopup" @close="closePopup">
      <SearchPopupComponent @close="closePopup" :forceSearch="true" />
    </PopupComponent>
    <FloatingCartComponent />
  </div>

</template>

<script>
// @ is an alias to /src
import ToolbarComponent from '@/components/site/ToolbarComponent.vue'
import FooterComponent from '@/components/site/FooterComponent.vue'
import PopupComponent from '@/components/PopupComponent.vue'
import PopupRendererComponent from '@/components/popup/PopupRendererComponent.vue'
import CartPopupComponent from '@/components/popup/CartPopupComponent.vue'
import SelectCityPopup from '@/views/popup/SelectCityPopup.vue'
import FloatingCartComponent from '@/components/floating/FloatingCartComponent.vue'
import SearchPopupComponent from '@/components/popup/SearchPopupComponent.vue';
// import LoadingWrapper from '@/components/LoadingWrapper.vue'
import {fetch2} from '@/modules/configuration.js'
export default {
  components: {
    ToolbarComponent,
    FooterComponent,
    PopupComponent,
    SelectCityPopup,
    PopupRendererComponent,
    CartPopupComponent,
    FloatingCartComponent,
    SearchPopupComponent
    // LoadingWrapper
  },
  data() {
    return {
      loading: true,
      showSearchPopup: false,
      data: {}
    }
  },
  mounted() {
    if (this.$route.meta.fullscreen) {
      this.loading = false;
      return;
    }
    this.getData();
  },
  computed: {
    showPopup() {
      return this.$store.state.popupOpen;
    },
    popupVarient() {
      return this.$store.state.popupVarient;
    },
    showSelectCity() {
      return this.$store.state.selectCityOpen;
    },
    popupData() {
      return this.$store.state.popupData;
    },
    popupUrl() {
      return this.$store.state.popupUrl;
    },
  },
  methods: {
    closePopup(success, response, requestId) {
      this.showSearchPopup = false;
      this.$store.dispatch('hidePopup');
      this.$store.dispatch('hideSelectCity');
      if (success) {
        this.emitter.emit("silent-refresh", {success: true, data: response, requestId: requestId});
      }
    },
    async getData() {
      const data = await fetch2(this, "/outer");
      this.data = data;
      this.loading = false;
      if (data.facebook_connect_data) {
        this.addFacebookCode(data.facebook_connect_data);
      }
    },
    addFacebookCode(fbData) {
      window.fbData = fbData;
      window.fbAsyncInit = function () {
        // eslint-disable-next-line 
        FB.init({
          appId: fbData.appID,
          version: fbData.api_version,
          xfbml: true
        });
      };

      // load facebook sdk script
      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));

    }
  },
  watch: {
    async $route(to/*, from*/) {
      if (to.hash === "#cart") {
        this.closePopup();
        await this.$nextTick();
        this.$store.dispatch('showPopupWithUrl', "/orders/cart_panel");
      }
      if (to.hash === "#popup:select_city") {
        const loc = window.location;
        history.pushState("", document.title, loc.pathname + loc.search);
        this.$store.dispatch('showSelectCity');
      }
      if (to.hash === "#popup:search_popup") {
        const loc = window.location;
        history.pushState("", document.title, loc.pathname + loc.search);
        this.showSearchPopup = true;
      }
    }
  }
}
</script>
<style scoped>
.container-content {
  margin: 0 20px 20px;
}

.container-content-inner {
  min-width: 320px;
  display: block;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .container-content {
    /* margin: 0 110px 110px; */
    margin: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  .container-content {
    /* margin: 0 110px 110px; */
    margin: 0;
  }

  .container-content-inner {
    margin-top: 63px !important;
  }
}
</style>
