<template>
  <div class="text-center">
    <div class="btn-group btn1-group text-center" data-toggle="buttons" role="group">
      <div v-for="(option,idx) in options" :key="idx">
        <input type="radio" class="btn-check" :name="name" autocomplete="off" :value="option.val" :checked="option.val==value" :id="`item_`+name+`_`+idx">
        <label class="btn btn1 text-center f14-l17-m" :for="`item_`+name+`_`+idx">
          {{ option.text }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    type: String,
    name: String,
    value: Number,
    options: Array
  }
}
</script>
<style scoped>
.btn1-group {
  background-color: #f3f1f1;
  border-radius: 17.5px;
  height: 35px;
  padding-top: 3px;
}
.btn1 {
  border: 0px solid #bbbaba;
  border-radius: 20px;
  background-color: #f3f1f1;
  margin: 0 5px;
  display: inline-block;
  text-align: center;
  color: #000000;
  text-decoration: none;
}

.btn-check:checked + .btn1 {
  background-color: white;
  /* color: #EF3D49;
  border-color: #EF3D49; */
}
</style>