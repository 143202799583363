<template>
  <div class="footer dmargin-top-50" v-if="data?.header" dir="rtl">
    <div class="d-flex flex-row mw1180" style="padding-top:13px; padding-bottom:13px;">
      <a :href="data.header.logo.href"><img src="@/assets/icons/logo-slogan.svg" class="img-fluid logo float-end mmargin-right-20" /></a>
      <div class="flex-fill"></div>
      <a class="btn btn-sm" :href="data.header.mail.href" :title="data.header.mail.href" target="_blank"><img src="@/assets/icons/footer-email.svg" /></a>
      <a class="btn btn-sm" :href="data.header.facebook.href" target="_blank"><img src="@/assets/icons/footer-facebook.svg" /></a>
      <a class="btn btn-sm" :href="data.header.instagram.href" target="_blank"><img src="@/assets/icons/footer-insta.svg" /></a>
    </div>
    <HorizontalSplitter />
    <div class="row g-0 mw1180">
      <div class="col-12 col-xl-6">
        <div class="container dmargin-top-20">
          <div class="row g-0">
            <div class="col-6 dmargin-top-20" v-for="p in data.links" :key="p">
              <a class="white-link f15-l23-m df20-l23-m mmargin-0-20" :href="p.href">{{p.text}} ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="mb-3 newsletter">
          <label for="newsletterControlInput" class="form-label f15-l23 df20-l23-m">{{data.newsletter.label}}</label>
          <form class="formfield-round-border" :action="data.newsletter.submit.url" method="post" ref="form" @submit.prevent="false" @keyup.enter="onSubmit">
            <div class="inner-addon left-addon" style="border-radius:18px; background-color: #38383B; border: 1px solid white; padding-left: 40px; padding-right:10px;">
              <input type="email" class="form-control f15-l23 color-white" :id="data.newsletter.field.data_name" :name="data.newsletter.field.data_name" :placeholder="data.newsletter.field.default_text" style="border:0; background-color: #38383B;">
              <img @click="onSubmit()" class="addon pointer" src="@/assets/icons/newsletter-arrow.svg" />
            </div>
          </form>
          <div class="f15-l23 df20-l23-m text-center">{{newsLetterSuccessMessage}}</div>
        </div>
      </div>
    </div>
    <HorizontalSplitter class="dmargin-top-20" />
    <div class="copyright">
      <div class="f10-l10 df14-l10">
        {{data.bottom.copy_rights}}
        |
        <a :href="data.bottom.links.tos.href" class="white-link nobreak">{{data.bottom.links.tos.text}}</a>
        |
        <a :href="data.bottom.links.privacy_policy.href" class="white-link nobreak">{{data.bottom.links.privacy_policy.text}}</a>
      </div>
    </div>
  </div>
</template>
<script>
import {formSubmit} from '@/modules/configuration.js'
import HorizontalSplitter from "@/components/generic/HorizontalSplitter.vue"
export default {
  components: {
    HorizontalSplitter
  },
  props: {
    data: Object
  },
  data() {
    return {
      newsLetterSuccessMessage: ""
    }
  },
  methods: {
    async onSubmit() {
      const form = this.$refs.form;
      const result = await formSubmit(this, form);
      this.newsLetterSuccessMessage = result.subscribed;
    }
  }
}
</script>
<style scoped>
.footer {
  background-color: #38383b;
  color: white;
  clear: both;
}

.footer .toolbar {
  padding-top: 13px;
  padding-bottom: 13px;
}
.footer .logo {
  height: 51px;
}
.footer .spacer-left {
  margin-left: 20px;
}
.footer .hr-split {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.footer .item {
  margin-left: 20px;
  margin-right: 20px;
}

.footer .newsletter {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.footer .copyright {
  margin: 20px 20px;
}

@media (max-width: 1200px) {
  .mmargin-right-20 {
    margin-right: 20px;
  }
}
</style>