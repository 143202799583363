<template>
  <div class="floating-cart position-fixed" v-if="cartQuantity>0">
    <div class="rounded-circle bg-red shadow pointer" style="padding: 10px" @click="openCart()">
      <img src="@/assets/shopping-cart.svg" />
    </div>
    <span class="position-absolute count translate-middle badge rounded-pill bg-white red shadow-sm">
      {{cartQuantity}}
    </span>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      visible: false
    }
  },
  computed: {
    ...mapGetters([
      'cartQuantity'
    ])
  },
  methods: {
    openCart() {
      if (this.$isDesktop(true)) {
        this.$store.dispatch('showPopupWithUrl', '/orders/cart_panel');
      } else {
        this.$router.push('/orders/checkout');
      }
    }
  }
}
</script>
<style scoped>
.floating-cart {
  display: block;
  z-index: 500;
  left: 10px;
  bottom: 10px;
  width: 45px;
  height: 45px;
}

.count {
  left: 85% !important;
  top: 3px !important;
}
</style>