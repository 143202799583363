<template>
  <div class="form-group form-group-border-bottom d-flex flex-column" dir="rtl" style="min-height: 33px;">
    <input type="hidden" :name="name" :value="parsedValue" />
    <div class="d-flex flex-row align-items-center padding-10 pointer position-relative" @click="toggleDatePicker">
      <SvgImage :src="field.icon" class="position-relative " style="top:-2px" />
      <div class="btn flex-grow-1 text-right" type="button"> {{parsedValue || label}} </div>
      <CaretComponent :open="datePickerVisible" class="position-relative translate-middle-y" />
    </div>
    <div v-show="datePickerVisible" class="w-100 " id="collapseDatepicker">
      <div class="card card-body margin-top-0 bg-inherit noborder">
        <!-- <div class="f15-l18-m df22-l32-m">{{title}}</div>
        <div class="d-flex flex-row">
          <div class="f14-l17-l red d-flex flex-row pointer" v-for="(elm,idx) in options" :key="idx" @click="selectOption(elm,idx)">
            <div class="padding-5">{{elm.text}}</div>
            <div class="red">|</div>
          </div>
        </div> -->
        <!-- <div class="f15-l18-m df22-l32-m">{{title}}</div> -->
        <div class="d-flex flex-row">
          <div class="f15-l18-m df22-l32-m d-flex flex-row pointer nowrap" v-for="(elm,idx) in options" :key="idx" @click="selectOption(elm,idx)">
            <div class="padding-5">{{elm.text}}</div>
            <div v-show="idx<options.length-1">|</div>
          </div>
        </div>
        <div :id="datepickerid" style="display:block;"></div>
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
import SvgImage from "@/components/generic/SvgImage";
import CaretComponent from "@/components/CaretComponent";
export default {
  components: {
    SvgImage,
    CaretComponent
  },
  props: ["label", "type", "name", "value", "field"],
  data() {
    return {
      fieldValue: "",
      fieldValueIsDate: true,
      popupContent: {},
      options: [],
      datePickerVisible: false,
      datepickerid: 'datepicker'
    }
  },
  mounted() {
    this.datepickerid = 'datepicker' + this._.uid;
    if (this.value) {
      this.fieldValue = this.value.split("/").reverse().join("-");
    }
    this.popupContent = this.field.popup_content;
    if (this.popupContent) {
      this.options = this.field.popup_content[0].options;
    }

    this.setupDatePicker();

  },
  unmounted() {
    // console.log('destroy datepicker', this.datepickerid);
    $("#" + this.datepickerid).datepicker('destroy');
  },
  methods: {
    async setupDatePicker() {
      // console.log('mount datepicker', this.datepickerid);
      const this$ = this;
      await this.$nextTick();
      $("#" + this.datepickerid).datepicker({
        autoClose: true,
        format: "dd/mm/yyyy",
        language: "he",
        rtl: true,
        todayBtn: false,
        todayHighlight: true,
        startDate: 'tomorrow',
        container: "#" + this.datepickerid
      })
        .on('changeDate', function () {
          this$.fieldValueIsDate = true;
          this$.fieldValue = $('#' + this$.datepickerid).datepicker('getFormattedDate');
          this$.toggleDatePicker();
          this$.$emit("change");
        });
      if (this.value) {
        $("#" + this.datepickerid).datepicker('update', this.value);
      }
    },
    selectOption(elm) {
      this.fieldValue = elm.val;
      this.datePickerVisible = false;
    },
    toggleDatePicker() {
      this.datePickerVisible = !this.datePickerVisible;
    }
  },
  computed: {
    dir() {
      return "rtl";
    },
    parsedValue() {
      if (this.fieldValueIsDate) {
        return this.fieldValue.split("-").reverse().join("/");
      }
      return this.fieldValue;
    },
    placeholder() {
      if (!this.field) return "";
      if (this.field?.default_text) return this.field?.default_text.text;
      return "";
    },
    title() {
      try {
        return this.popupContent[0].title;
      } catch (e) {
        return '';
      }
    }
  }
}
</script>
