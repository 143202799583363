"use strict"
import {useFetch} from "@vueuse/core";

const masterDebug = false;
const _configuration = {
    // baseUrl: 'http://staging.yummi.co.il:8080/',
    // baseUrl: 'https://staging.yummi.co.il:443/',
    // baseUrl: 'http://localhost:3000/',
    baseUrl: 'https://legacy.yummi.co.il:443/',
    defaultHeaders: {
        Accept: 'application/json',
        //Authorization: 'Basic eXVtbWk6eTEyMzQ1'
    }
};

async function _fetch3($this, obj) {
    let link = '';
    const url = obj.url;
    const method = obj.method || "get";
    if (url) {
        link = url[0] == '/' ? url.substring(1) : url; //remove first /
    }
    if (obj.addQueryString) {
        const query = $this.$route.query;
        let queryStringArr = [];
        for (const param in query) {
            queryStringArr.push(param + "=" + encodeURIComponent(query[param]));
        }
        link += (link.indexOf("?") > 0 ? "&" : "?") + queryStringArr.join("&");
    }
    if (obj.queryString) {
        link += (link.indexOf("?") > 0 ? "&" : "?") + obj.queryString;
    }
    // link = link.replace(/&&+/, "&").replace(/&=/, '=');
    if (obj.pushHistory) {
        const firstSlash = link.indexOf("/", 5);
        const addToHistoryPath = link.substring(firstSlash);
        history.pushState({}, null, addToHistoryPath);
        // console.log("add to history: " + addToHistoryPath);
    }
    try {
        let response = await fetch((configuration.baseUrl + link), {
            method: method,
            redirect: "manual",
            credentials: 'include',
            headers: {
                Accept: 'application/json'
            }
        });
        if (response.status == 404) {
            const d = await response.json();
            $this.$router.push({name: 'not_found', params: {d: JSON.stringify(d)}});
            return {};
        } else if (response.status != 200) {
            const d = await response.json();
            $this.$router.push({name: 'e500', params: {d: JSON.stringify(d)}});
            return {};
        }
        let data = await response.json();
        let parentRedirectUrl = data.redirect_parent_to || data.redirect_parent;
        if (parentRedirectUrl) {
            parent.location.href = parentRedirectUrl;
            return;
        }
        let redirectUrl = data.redirect_to || data.redirect;
        if (redirectUrl) {
            window.location = redirectUrl;
            return;
        }
        if (data?.popup?.prompt_json) {
            $this.$store.dispatch('showPopup', data.popup.prompt_json.popup_content);
        }
        return data;
    } catch (e) {
        $this.$router.push({name: 'e500', params: {d: JSON.stringify({title: 'oops'})}});
    }
}

async function _fetch2($this, url, method) {
    return await _fetch3($this,
        {
            url: url,
            method: method
        });
}

//todo: remove?
async function _fetch2Ref(urlRef) {
    return useFetch(urlRef, {
        async beforeFetch({url, options/*, cancel*/}) {
            if (masterDebug) {
                console.log('fetch2: ' + url);
            }
            options.headers = {
                ...options.headers,
                ...configuration.defaultHeaders
            }
            options.credentials = 'include';
            options.refetch = true;
            return {
                options,
            }
        },
    }).get().json();
}

function validateForm(form) {
    if (!form) return true;
    if (!form.checkValidity()) return false;
    if (!form.classList.contains('needs-validation')) {
        return true;
    }
    const inputs = form.getElementsByTagName("input");
    let invalidCount = 0;
    for (let i = 0; i < inputs.length; i++) {
        let elm = inputs[i];
        if (elm.type == 'hidden') continue;
        if (elm.required) {
            if (elm.type == 'checkbox') {
                if (!elm.checked) {
                    document.getElementById(elm.name + "invalid").classList.add("show");
                    invalidCount++;
                } else {
                    document.getElementById(elm.name + "invalid").classList.remove("show");
                }
                continue;
            }
            const value = (elm.value || '').trim();
            if (value == '') {
                document.getElementById(elm.name + "invalid").classList.add("show");
                invalidCount++;
            } else {
                document.getElementById(elm.name + "invalid").classList.remove("show");
            }
        }
    }
    // console.log('need validation');
    return invalidCount == 0;
}

async function _formSubmit($this, form, action, formBody) {
    if (document.getElementById("error_message")) {
        document.getElementById("error_message").innerHTML = '';
    }
    const formValid = validateForm(form);
    if (formValid == false) return {};

    let formData = (form ? new FormData(form) : new FormData());
    if (formBody) {
        for (let k in formBody) {
            formData.append(k, formBody[k]);
        }
    }
    if (form && (form.method == 'get' || form.method == 'GET')) {
        const queryString = new URLSearchParams(formData).toString();
        const formAction = (action || form.action);
        const hasQueryString = formAction.indexOf('?') > 0;
        const url = formAction + (hasQueryString ? "&" : "?") + queryString;
        // console.log(url);

        if (url.startsWith('http://') || url.startsWith('https://')) {
          window.location.href = url; // Directly navigate to the absolute URL
        } else {
          $this.$router.push(url); // Push the relative route
        }
        return {};
    }
    let ac = _baseUrl(action || form.action);
    let response = await fetch(ac || form.action, {
        method: "post",
        redirect: "manual",
        credentials: 'include',
        headers: {
            Accept: 'application/json'
        },
        body: (form || formBody) ? formData : null
    });
    if (response.status == 404) {
        $this.$router.push('/not_found');
        return {};
    }
    if (response.status != 200) {
        $this.$router.push('/oops');
        return {};
    }
    let data = await response.json();
    let redirectUrl = data.redirect_to || data.redirect;
    if (redirectUrl) {
        window.location = redirectUrl;
        return {};
    }
    if (data.city_required) {
        $this.$store.dispatch('showSelectCity');
        return {};
    }

    let formErrors = data?.form?.form_errors || data[Object.keys(data)[0]]?.form_errors;

    if (formErrors && formErrors?.message) {
        document.getElementById("error_message").innerHTML = formErrors.message;
        // return {};
    }

    if (formErrors && data[Object.keys(data)[0]]?.form) {
        // form.classList.add('invalid');
        let respForm = data[Object.keys(data)[0]].form;
        for (let i = 0; i < respForm.fields.length; i++) {
            let field = respForm.fields[i];
            let elm = document.getElementById(field["data_name"] + 'invalid');
            if (elm) {
                if (field.error_message) {
                    elm.innerHTML = field.error_message.join('<br/>');
                } else {
                    elm.innerHTML = '';
                }
            }
        }
        window.scrollTo({top: 0, behavior: 'smooth'});
        return {};
    }

    return data;
}

function _baseUrl(url) {
    if (!url) return url;
    if (url.indexOf('http') == 0) {
        const {pathname, search} = new URL(url);
        return configuration.baseUrl + pathname.substring(1) + search;
    }
    if (url.indexOf('/') == 0) {
        return configuration.baseUrl + url.substring(1);
    }
    return configuration.baseUrl + url;
}

function _addGoogleAnalyticsDataLayer(dataLayer) {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      
      // Merge the provided dataLayer with the existing dataLayer
      if (dataLayer && typeof dataLayer === 'object') {
        if (Array.isArray(dataLayer)) {
          window.dataLayer.push(...dataLayer);
        } else {
          window.dataLayer.push(dataLayer);
        }
      }
      
      // Add Google Analytics script
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-5H7CHJT');
    }
  }


export const configuration = _configuration;
export const fetch2 = _fetch2;
export const fetch3 = _fetch3;
export const fetch2Ref = _fetch2Ref;
export const baseUrl = _baseUrl;
export const formSubmit = _formSubmit;
export const addGoogleAnalyticsDataLayer = _addGoogleAnalyticsDataLayer;
