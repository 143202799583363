<template>
  <div>
    <label class="col-12">
      {{label}}
    </label>
    <div class="btn-group btn-group-toggle row gx-2 gy-2" data-toggle="buttons">
      <div v-for="(option,idx) in options" :key="idx" :class="colsize(option)">
        <label class="text-center" style="margin-right:20px;">
          <input type="checkbox" :name="dataName(option)" autocomplete="off" :value="option.val" v-model="itemChecked[idx]"> {{ option.field?.label }}
        </label>
        <FormFieldComponent v-if="option.field" :field="option.field" v-show="itemChecked.length>=idx && itemChecked[idx]" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    label: String,
    type: String,
    name: String,
    options: Array,
    subfield: Object,
  },
  data() {
    return {
      itemChecked: []
    }
  },
  mounted() {
    this.itemChecked.length = this.$props.options.length;
    this.itemChecked.fill(true);
  },
  methods: {
    colsize(option) {
      if (option.field) return 'col-12';
      return 'col-' + parseInt(12 / this.options.length);
    },
    dataName(option) {
      return option.field?.data_name || 'data_name_undefined';
    }
  }
}
</script>