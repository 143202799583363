<template>
  <div>
    <label v-if="label" class="col-12">
      {{label}}
    </label>
    <div class="btn-group btn-group-toggle row g-2 w-100" data-toggle="buttons">
      <div v-for="(option,idx) in options" :key="idx" class="col-4 position-relative">
        <input type="radio" class="btn-check" :name="name" :value="option.val" :checked="option.val==value" :id="`item_` + name + `_` + idx ">
        <label class="btn btn1 text-center align-middle f14-l18" :for="`item_` + name + `_` + idx " v-html="option.text || option.text_html"></label>
        <img src="@/assets/icons/check-selected.png" class="btn1-check-mark" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    type: String,
    name: String,
    value: Number,
    options: Array
  }
}
</script>
<style scoped>
.btn1 {
  border: 1px solid #bbbaba;
  border-radius: 20px;
  background: white;
  margin: 0 5px;
  display: block;
  text-align: center;
  color: #171717;
  text-decoration: none;
  width: 100%;
}

.btn-check:checked + .btn1 {
  color: #ef3d49;
  border-color: #ef3d49;
  background-color: #f9f9f9;
}

.btn1-check-mark {
  position: absolute;
  top: 10px;
  right: 20px;
  display: none;
}

.btn-check:checked ~ .btn1-check-mark {
  display: block;
}

/* label {
  height: 50px;
  //line-height: 34px !important;
} */
</style>