<template>
  <div>
    <div class="d-flex flex-column" dir="rtl">
      <input type="hidden" :name="name" v-model="dataToSend" />
      <div class="d-flex flex-row  align-items-center">
        <input class="red-checkbox" type="checkbox" :id="name" autocomplete="off" @change="changed()" v-model="checked" value="true" />
        <label class="text-center" :for="name">{{label}}</label>
      </div>
      <div v-if="checked" class="row dnominusmargin">
        <FormFieldComponent style="margin:0 0" v-for="(field,idx) in subfields" :field="cuztomizeField(field)" :key="idx" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    label: String,
    type: String,
    name: String,
    options: Array,
    subfields: Object,
    field: Object
  },
  data() {
    return {
      checked: false,
      dataToSend: 0
    }
  },
  mounted() {
    if (this.field.checked) {
      this.checked = true;
      this.dataToSend = this.field.value;
    }
  },
  methods: {
    // colsize(option) {
    //   if (option.field) return 'col-12';
    //   return 'col-' + parseInt(12 / this.options.length);
    // },
    dataName(option) {
      return option.field?.data_name || 'data_name_undefined';
    },
    changed() {
      this.dataToSend = this.checked ? 1 : 0;
    },
    cuztomizeField(field) {
      // if (field.data_name == "name_for_receipt") {
        field["labelClass"] = "col-5 col-xl-5";
        field["inputContainerClass"] = "col-7 col-xl-7";
      // }
      // if (field.data_name == "id_for_receipt") {
      //   field["labelClass"] = "col-5 col-xl-5";
      //   field["inputContainerClass"] = "col-7 col-xl-7";
      // }
      return field;
    }
  }
}
</script>