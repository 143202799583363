<template>
  <div :class="clazz" >
    <div :class="'d-flex flex-row align-items-center pointer button_' + variant + ' w-auto f16-l10 df18-l10 dbutton'" @click="gotoLink" @keyup.enter="gotoLink" style="padding: 0 16px;" tabindex="0" :type="isSubmit" >
      <slot name="right"></slot>
      <slot></slot>
      <span v-if="caption" class="button-content flex-grow-1">{{ caption }}</span>
      <slot name="left"></slot>
    </div>
  </div>
</template>
<script>
import {formSubmit} from '@/modules/configuration.js'
export default {
  props: {
    clazz: String,
    caption: String,
    link: String,
    newTab: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: "red",
      validator: function (value) {
        return ['red', 'facebook', 'white', 'red_dropdown'].includes(value)
      }
    },
    isSubmitButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSubmit() {
      return this.isSubmitButton ? "submit" : ""
    }
  },
  methods: {
    gotoLink() {
      if (this.variant == 'facebook') {
        this.performFacebookLogin();
        return;
      }

      if (this.link) {
        if (this.newTab) {
          window.open(this.link);
        } else if (this.link.startsWith('#')) {
          window.location = this.link;
        } else if (this.link.startsWith('http')) {
          window.location = this.link;
        } else {
          this.$router.push(this.link);
        }
      }
    },
    performFacebookLogin() {
      // console.log('facebook login');
      // eslint-disable-next-line
      FB.login((response) => {
        if (response.status == 'connected') {
          formSubmit(this, null, window.fbData.login_url, {access_token: response.authResponse.accessToken, vue: 1}).then((res) => {
            if (process.env.NODE_ENV === 'development') {
              console.log(res);
            }
          });
        }
      }, {
        scope: window.fbData.permissions,
        // auth_type: $(login_elem).attr('data-auth_type')
      });
    }
  }
}
</script>
<style scoped>
.button_white {
  height: 44px;
  line-height: 44px !important;
  border-radius: 22px;
  border: 1px solid #ef3d49;
  color: #ef3d49;
  background-color: white;
  margin: auto auto;
  text-align: center;
  cursor: pointer;
}

.button_red {
  height: 44px;
  line-height: 44px !important;
  border-radius: 22px;
  background-color: #ef3d49;
  color: white;
  margin: auto auto;
  text-align: center;
  cursor: pointer;
  border-color: #ef3d49;
  border-width: 0px;
}

.button_red_dropdown {
  height: 30px;
  line-height: 44px !important;
  border-radius: 22px;
  background-color: #ef3d49;
  color: white;
  margin: auto auto;
  text-align: center;
  cursor: pointer;
  border-color: #ef3d49;
  border-width: 0px;
}

.button_facebook {
  height: 44px;
  line-height: 44px !important;
  border-radius: 22px;
  /* background-color: #ef3d49; */
  border: 1px solid #3a67d9;
  color: #3a67d9;
  margin: auto auto;
  text-align: center;
  cursor: pointer;
}

.button-content {
  padding: 0 14px;
}
</style>