<template>
  <div class="btn-group btn-group-toggle text-center row gx-2 gy-2 bordered" data-toggle="buttons" style="width:100%;">
    <div v-for="(option,idx) in options" :key="idx" class="col-4">
      <!-- <label class="text-center" style="margin-right:20px;">
          <input type="radio" :name="name" autocomplete="off" :value="option.val" :checked="option.val==value"> <span v-html="option.text_html" />
        </label> -->
      <input type="radio" class="btn-check" :name="name" autocomplete="off" :value="option.val" :checked="option.val==value" :id="`item_` + name + `_` + idx ">
      <label class="btn btn1 text-center align-middle f14-l18" :for="`item_` + name + `_` + idx " v-html="option.text_html" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    type: String,
    name: String,
    value: Number,
    options: Array
  }
}
</script>
<style scoped>
.btn1 {
  border: 1px solid #bbbaba;
  border-radius: 20px;
  background: white;
  margin: 0 5px;
  display: inline-block;
  text-align: center;
  color: #171717;
  text-decoration: none;
  width: 100%;
}

.btn-check:checked + .btn1 {
  color: #ef3d49;
  border-color: #ef3d49;
}
</style>