<template>
  <div class="form-group row form-group-border-bottom align-items-center" :style="field.in_popup ? 'margin-right: 0px; margin-left:0px;' : ''">
    <label v-if="label" :for="name" :class="labelSize" class="col-form-label" :style="labelStyle"><span v-if="field.required" class="required">*</span>{{label}}</label>
    <div class="align-items-center" :class="colSize">
      <input autocomplete="off" :type="type" class="form-control-plaintext  additionalclass" :id="name" :aria-describedby="`fieldDescription_` + name" :name="name" :value="valueParsed" :dir="dir" :placeholder="placeholder" :required="field.required" >
      <div class="invalid-feedback" :id="`${name}invalid`">{{field.missing_error}}</div>
      <!-- <small :id="`fieldDescription_` + name" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    type: String,
    name: String,
    value: String,
    field: Object,
  },
  computed: {
    dir() {
      if (this.type == 'email') return "ltr";
      if (this.type == 'password') return "ltr";
      if (this.field?.dir == 'ltr') return this.field?.dir;
      return "rtl";
    },
    valueParsed() {
      // if (!this.value) return this.value;
      if (this.type == 'date') {
        return this.field.value;
      }
      return this.value;
    },
    placeholder() {
      if (!this.field) return "";
      if (this.field?.default_text) return this.field?.default_text;
      return "";
    },
    colSize() {
      if (!this.label) return 'col-12';
      return this.field.inputContainerClass || 'col-6 col-xl-8';
    },
    labelSize() {
      return this.field.labelClass || 'col-auto col-xl-auto';
    },
    labelStyle() {
      if (this.field?.dir == 'ltr') return "padding-left: 12px;";
      return ""; 
    },
  }
}
</script>
<style scoped>
.additionalclass {
  position: relative;
  top: 2px;
}
.required {
  color: red;
  margin-left: 5px;
}

</style>