import {createStore} from 'vuex';
import {fetch2} from '@/modules/configuration.js'
export default createStore({
  state() {
    return {
      popupOpen: false,
      popupData: {},
      popupUrl: {},
      selectCityOpen: false,
      popupVariant: "center",
      cartCount: 0
    }
  },
  getters: {
    cartQuantity: state => state.cartCount
  },
  mutations: {
    showSelectCity: function (state) {
      state.selectCityOpen = true;
    },
    hideSelectCity: function (state) {
      state.selectCityOpen = false;
    },
    showPopup: function (state, data, popupVariant) {
      state.popupData = data;
      state.popupOpen = true;
      state.popupVariant = popupVariant || "center";
    },
    hidePopup: function (state) {
      state.popupData = {};
      state.popupUrl = {};
      state.popupOpen = false;
    },
    selectDish: function (state, data) {
      state.data = data;
      state.dataType = 'dish';
    },
    showPopupWithUrl: function (state, url, popupVariant) {
      state.popupUrl = {url: url};
      state.popupOpen = true;
      state.popupVariant = popupVariant || "center";
    },
    updateCartCount(state, data) {
      state.cartCount = data;
    }
  },
  actions: {
    showSelectCity({commit}) {
      commit('showSelectCity');
    },
    hideSelectCity({commit}) {
      commit('hideSelectCity');
    },
    showPopup({commit}, data, variant) {
      commit('showPopup', data, variant);
    },
    hidePopup({commit}) {
      commit('hidePopup');
    },
    showPopupWithUrl({commit}, url, variant) {
      commit('showPopupWithUrl', url, variant);
    },
    fetchCartQuantityData() {
      fetch2(null, "/orders/cart_status").then(response => {
        this.commit('updateCartCount', response.dishes_count);
      });
    }

  },
  modules: {
  }
})
