<template>
  <div :style="visualClass">
    <img :src="img.url || img.src || img.href" :width="img.width" :height="img.height" :style="style">
      <img v-if="magnifier" :src="magnifier.icon" class="image-preview-magnifier" alt="" @mouseenter="showImageMagnifier()" @touchstart="toggleImageMagnifier()" @mouseleave="hideImageMagnifier()"/> 
      <!-- <div v-if="showPreviewPane" class="preview-pane-placeholder"></div> -->
      <div v-if="showPreviewPane" class="preview-pane-placeholder"></div>
  </div>
</template>
<script>
export default {
  props: {
    img: {},
    magnifier: {}
  },
  computed: {
    style() {
      if (this.img.width && this.img.height) {
         return "width:" + this.img.width + "px; height:" + this.img.height + "px";
      }
      return "";
    },
    visualClass() {
      if ( this.magnifier ) {
        return "position: relative;";
      }
      return "";
    }
  },
  data() {
    return {
      showPreviewPane: false
    };
  },
  methods: {
    showImageMagnifier() {
      // Remove any existing image previews
      this.hideImageMagnifier();
      this.showPreviewPane = true;
      // Extracting necessary information from the magnifier prop
      var { height: imageHeight, width: imageWidth } = this.magnifier;
      let isMobile = false;

      // Check if the user is on a mobile device
      if (this.$isMobile()) {
        isMobile = true;
        // Adjust image size for mobile devices
        imageHeight *= 0.625;
        imageWidth *= 0.625;
      }
      // Create the preview pane element
      const previewPane = document.createElement('div');
      previewPane.id = 'image-preview';
      previewPane.innerHTML = `<img src="${this.magnifier.url}" height="${imageHeight}" width="${imageWidth}">`;

      // Calculate position for the preview pane
      // const magnifierOffset = this.$el.getBoundingClientRect();
      previewPane.style.height = `${imageHeight}px`;
      previewPane.style.width = `${imageWidth}px`;
      // previewPane.style.top = `${Math.max(150, magnifierOffset.top - imageHeight - window.scrollY)}px`;
      // previewPane.style.left = `${isMobile ? (document.body.offsetWidth / 2 - imageWidth / 2) : (magnifierOffset.left - (magnifierOffset.left < document.documentElement.clientWidth / 2 ? -40 : imageWidth + 20) - window.scrollX)}px`;
      previewPane.style.right = isMobile ? '30px' : '25px';
      previewPane.style.top = isMobile ? '-125px' : '-160px';
      previewPane.style.position = 'absolute';
      previewPane.style.zIndex = '1000';
      previewPane.style.border = '2px solid #eee';
      previewPane.style.boxShadow = '0px 5px 10px #888';
      previewPane.style.background = '#f9f5ee';
      previewPane.style.backgroundRepeat = 'no-repeat';
      previewPane.style.backgroundPosition = 'center';


      // Create the close button element
      const closeButton = document.createElement('div');
      closeButton.innerText = 'X';
      closeButton.style.position = 'absolute';
      closeButton.style.top = '10px';
      closeButton.style.right = '10px';
      closeButton.style.backgroundColor = 'rgba(239, 61, 73, 1)';
      closeButton.style.color = 'white';
      closeButton.style.border = 'none';
      closeButton.style.borderRadius = '50%';
      closeButton.style.width = '25px';
      closeButton.style.height = '25px';
      closeButton.style.cursor = 'pointer';
      closeButton.style.fontSize = '16px';
      closeButton.style.display = 'flex';
      closeButton.style.justifyContent = 'center';
      closeButton.style.alignItems = 'center';
      closeButton.style.lineHeight = '25px'; // Ensure the text is vertically centered
      closeButton.addEventListener('click', this.hideImageMagnifier);
      previewPane.appendChild(closeButton);

      // Append the preview pane to the body and fade it in
      this.$el.appendChild(previewPane);
      
      // Add click event listener to body to hide the preview pane
      document.body.addEventListener('touchstart ', this.hideImagePreview);
    },
    toggleImageMagnifier() {
      if (this.showPreviewPane) {
        this.hideImageMagnifier();
      }else {
        this.showImageMagnifier();
      }
    },
    hideImageMagnifier() {
      this.showPreviewPane = false;
      const previewPane = document.getElementById('image-preview');

      if (previewPane) {
        previewPane.remove();
        // Remove the click event listener from the body
        document.body.removeEventListener('touchstart ', this.hideImagePreview);
      }
    }
  }
}
</script>
<style scoped>
  .visual {
    position: relative;
  }

  
  .image-preview-magnifier {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: white;
    opacity:0.6;
    padding: 8px 4px 4px 8px;
    border-top-left-radius: 12px;
    border: 1px solid white;
    box-sizing: content-box;
    cursor: default;
    width: auto !important;
    height: 12px;
    width: 12px;
  }
  
  .preview-pane-placeholder {
    position: absolute;
    z-index: 9998; /* Ensure it's below the previewPane */
    /* Match the size and position of the previewPane */
  }

  #image-preview {
    position: absolute;
    z-index: 9999; /* Ensure the preview pane is on top of other elements */
    /* Add any additional styles for the preview pane */
  }

 .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    .image-preview-magnifier {
      border-top-left-radius: 9px;
      padding: 5px 2px 2px 5px;
      opacity: 1;
      height: 11px;
      width: 11px;
    }
  }
</style>