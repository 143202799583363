

const SIZE_TYPE = {
    DESKTOP: 'desktop',
    TABLET: 'tablet',
    MOBILE: 'mobile'
};

const sizeType = () => {
    const s = window.innerWidth;
    if (s >= 1200) return SIZE_TYPE.DESKTOP;
    if (s >= 768) return SIZE_TYPE.TABLET;
    return SIZE_TYPE.MOBILE;
}

// let sizeType() = sizeType();

const iOS = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const GOOGLE = () => { return /Chrome/.test(navigator.userAgent) && /Android/.test(navigator.userAgent)};

const inAppFaceBook = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}

export default {
    install: (app/*, options*/) => {
        app.config.globalProperties.$responsive = (jsonPath) => {
            if (jsonPath == null) return null;
            return jsonPath[sizeType()] ;//|| jsonPath[SIZE_TYPE.MOBILE] || jsonPath[SIZE_TYPE.TABLET] || jsonPath[SIZE_TYPE.DESKTOP];
        }

        app.config.globalProperties.$isDesktop = (allowLower) => {return (sizeType() == SIZE_TYPE.DESKTOP || (allowLower && sizeType() == SIZE_TYPE.TABLET && !inAppFaceBook()))};
        app.config.globalProperties.$isTablet = (allowLower) => {return (sizeType() == SIZE_TYPE.TABLET || (allowLower && sizeType() == SIZE_TYPE.MOBILE))};
        app.config.globalProperties.$isMobile = () => {return (sizeType() == SIZE_TYPE.MOBILE || inAppFaceBook())};
        app.config.globalProperties.$isIOS = () => {return iOS()};
        app.config.globalProperties.$isGOOGLE = () => {return GOOGLE()};
        app.config.globalProperties.$sizeType = sizeType();
    }
}