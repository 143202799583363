<template>
  <div>
    <label class="col-12 margin-top-20" v-if="label">
      {{label}} <span v-if="field?.description" class="c7f">{{field.description}}</span>
    </label>
    <div class="btn-group btn-group-toggle col-12 row gx-2 gy-2" data-toggle="buttons">
      <div v-for="(option,idx) in options" :key="idx" :class="colsize(option)" class="position-relative">
        <input type="checkbox" class="btn-check" :id="`item_`+name+`_`+idx" autocomplete="off" :value="option.val" v-model="itemChecked[idx]">
        <label class="btn btn1 text-center w-100 f14-l18 padding-all-10" :for="`item_`+name+`_`+idx">
          <SvgImage v-if="option.field?.icon" :src="option.field?.icon" style="width: 23px; height: 16px;" />
          <div class="margin-top-10">{{ option.text || option.label || option.field?.label}}</div>
        </label>
        <img src="@/assets/icons/check-selected.png" class="btn1-check-mark" />
      </div>
    </div>
    <div id="options" class="row dnominusmargin">
      <FormFieldComponent v-for="(option,idx) in options" :key="idx" :field="option.field" v-show="itemChecked.length>=idx && itemChecked[idx]" />
    </div>
  </div>
</template>
<script>
import SvgImage from "@/components/generic/SvgImage"
export default {
  components: {
    SvgImage
  },
  props: {
    label: String,
    type: String,
    name: String,
    options: Array,
    field: Object,
    subfield: Object,
  },
  data() {
    return {
      itemChecked: []
    }
  },
  mounted() {
    this.itemChecked.length = this.$props.options.length;
    this.itemChecked.fill(false);
  },
  methods: {
    colsize(option) {
      if (option.field) return 'col-6';
      return 'col-' + parseInt(12 / this.options.length);
    },
    dataName(option) {
      return option.field?.data_name || 'data_name_undefined';
    }
  }
}
</script>
<style scoped>
.btn1 {
  border: 1px solid #bbbaba;
  border-radius: 5px;
  background: white;
  margin: 0 5px;
  display: inline-block;
  text-align: center;
  color: #171717;
  text-decoration: none;
}

.btn-check:checked + .btn1 {
  color: #ef3d49;
  border-color: #ef3d49;
  background-color: #f9f9f9;
}

.btn1-check-mark {
  position: absolute;
  top: 10px;
  right: 20px;
  display: none;
}

.btn-check:checked ~ .btn1-check-mark {
  display: block;
}
</style>