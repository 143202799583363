<template>
  <div>
    <div class="form-group form-group-border-bottom align-items-center d-flex flex-row position-relative" dir="rtl">
      <input type="text" :value="field.text.carrier_delivery.text" class="form-control-plaintext" list="datalistOptions" :id="name" :name="name" :placeholder="field.text.carrier_delivery.default_text" style="padding-right:42px;" :disabled="isDisabled">
      <SvgImage :src="field.icon" class="position-absolute" style="right:22px; height:20px;" />
      <datalist id="datalistOptions" dir="rtl" style="text-align:right;">
        <option v-for="opt in options" :value="opt" :key="opt"></option>
      </datalist>
      <!-- <small :id="`fieldDescription_` + name" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
    </div>
    <div v-if="isDisabled" class="invalid-feedback" :id="`${name}invalid`">{{field.disabled_error}}</div>
  </div>
</template>
<script>
import SvgImage from "@/components/generic/SvgImage";
export default {
  components: {
    SvgImage,
  },
  props: {
    label: String,
    type: String,
    name: String,
    selected: String,
    options: Array,
    field: Object,
    simple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDisabled() {
      return this.field.is_disabled;
    }
  }
}
</script>