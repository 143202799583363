<template>
  <div class="text-center">
    <div class="btn-group text-center" data-toggle="buttons" role="group">
      <div v-for="(option,idx) in options" :key="idx">
        <input type="radio" class="btn-check" :name="name" autocomplete="off" :value="option.val" :checked="option.val==value" :id="`item_`+name+`_`+idx">
        <label class="btn btn1 text-center" :for="`item_`+name+`_`+idx">
          {{ option.text }}
          </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    type: String,
    name: String,
    value: Number,
    options: Array
  }
}
</script>
<style scoped>
.btn1 {
  border: 1px solid #bbbaba;
  border-radius: 20px;
  background: white;
  margin: 0 5px;
  display: inline-block;
  text-align: center;
  color: #171717;
  text-decoration: none;
}

.btn-check:checked + .btn1 {
  color: #EF3D49;
  border-color: #EF3D49;
}
</style>