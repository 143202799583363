<template>
  <div :class="claz"></div>
</template>
<script>
export default {
  props: {
    open: Boolean
  },
  computed: {
    claz() {
      return this.open ? "caret-up" : "caret-down";
    }
  }
}
</script>
<style scoped>
.caret-up {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000;
  margin-right:5px;
}

.caret-down {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  margin-right:5px;
}
</style>