<template>
  <div>
    <label class="col-12" v-if="label">
      {{label}}
    </label>
    <input type="hidden" :name="name" v-model="selectedValue" />
    <div class="form-group d-flex flex-column align-items-center position-relative">
      <div class="border-bottom w-100 padding-tb-10 pointer" v-for="(option,idx) in options" :key="idx" :value="optionValue(option)" @click="selectOption(option)">
        {{optionText(option)}} <span v-show="selectedValue==option.val" class="red"><img src="@/assets/icons/check_selected_address.svg" style="width:20px"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    type: String,
    name: String,
    selected: String,
    options: Array,
    simple: {
      type: Boolean,
      default: false
    },
    field: Object
  },
  data() {
    return {
      selectedValue: -1
    }
  },
  mounted() {
    this.selectedValue = this.field.selected;
  },
  methods: {
    selectOption(option) {
      this.selectedValue = option.val;
    },
    optionValue(option) {
      if (this.simple) {
        return option;
      }
      return option.val;
    },
    optionText(option) {
      if (this.simple) {
        return option;
      }
      return option.text;
    },
    selectEvent(trustedEvent, value) {
      if (trustedEvent) {
        this.open = value;
        return;
      }
      this.open = !this.open;
    }
  }
}
</script>
<style scoped>
.formselect {
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  background-color: inherit;
  border: 0;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

.formselect:focus,
.formselect:active {
  border: 0;
}
</style>