<template>
  <div class="form-group align-items-center">
    <input type="hidden" :name="name" :id="name + 'notselected'" value="" />
    <label v-if="label" :class="labelClass()">
      {{label}}
    </label>
    <div class="btn-group btn-group-toggle row gx-2 gy-2" data-toggle="buttons" :class="selectionClass()">
      <div v-for="(option,idx) in options" :key="idx" :class="innerClass(this.options.length)">
        <label class="text-center " >
          <input class="red-radio" type="radio" :name="name" autocomplete="off" :value="option.val" :checked="option.val==value" v-model="selected"> <span v-html="option.text"></span>
          <!-- class="nooption" -->
        </label>
        <div v-if="option.fields" v-show="this.selected==option.val">
          <FormFieldComponent v-for="(field,idx) in option.fields" :field="field" :key="idx" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    type: String,
    name: String,
    value: Number,
    options: Array,
    subfields: Object,
    field: Object
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    labelClass() {
      if (this.field.horizontal) {
        return "col-6";
      }
      return "col-12";
    },
    selectionClass() {
      if (this.field.horizontal) {
        console.log("horizontal");
        return "col-6 padding-right-20"; /*text-left*/
      }
      return "col-12";
    },
    innerClass(optionsNum) {
      var col_size = 12 / optionsNum
      if (this.field.horizontal) {
        return "col-6";
      }
      if (this.field.wide) {
        return "col-" + col_size;
      }
      return "col-12";
    }

  }
}
</script>
<style scoped>
.padding-right-20 {
  padding-right: 27px;
}
</style>