<template>
  <div class="vue-modal" v-show="open" @click="$emit('close')">
    <div :class="`f16-l19 d-flex flex-column vue-modal-inner-${variant2} ${containerClass}`" @click.stop="false">
      <div id="modalHeader" dir="rtl">
        <slot name="header" />
      </div>
      <div class="flex-grow-1 vue-modal-content" @click.stop="false">
        <slot />
      </div>
      <div id="modalFooter" dir="rtl">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
export default {
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    containerClass: {
      type: String,
      default: 'mw820'
    },
    variant: {
      type: String,
      default: 'center',
      validator(value) {
        return ['center', 'bottom', 'top-center'].includes(value)
      }
    }
  },
  computed: {
    variant2() {
       return this.$isIOS() ? 'top-center' : this.variant;
    }
  },
  mounted() {
    $("body").addClass('noscroll popupisopen');
  },
  unmounted() {
    $("body").removeClass('noscroll popupisopen');
  }

}
</script>
<style scoped>
/* *,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.vue-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 3000;
}

.vue-modal-inner-top-center {
  margin: 2rem auto;
  overflow-y: hidden;
  background-color: #fff;
  border-radius: 20px;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.3);
  max-height: 80%;
}


.vue-modal-inner-center {
  margin: 8rem auto;
  overflow-y: hidden;
  background-color: #fff;
  border-radius: 20px;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.3);
  max-height: 80%;
  /* position:fixed;  */
  /* top: calc(50% -  );  */
  /* left: calc(50% - 300px);  */
}

.vue-modal-inner-bottom {
  /* max-width: 500px; */
  max-height: 80%;
  /* margin: 2rem auto; */
  width: 100%;
  /* overflow-y: auto;
  position: fixed; */
  bottom: 0;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
}

@media (min-width: 768px) {
  .vue-modal-inner-bottom {
    left: 50%;
    left: 50% !important;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.vue-modal-content {
  position: relative;
  /* background-color: green; */
  overflow-y: auto;
  padding: 1rem;
}

.vue-modal-inner-bottom .vue-modal-content {
  border-width: 0;
}
</style>

