<template>
  <div class="form-check form-group d-flex flex-column" dir="rtl">
    <input type="hidden" :id="name + 'notselected'" :name="name" :value="offValue()" />
    <div class="d-flex flex-row  align-items-center">
      <input class="red-checkbox" type="checkbox" :value="onValue()" :id="name" :name="name" :checked="1==value" :required="field.required">
      <label class="f16-l23-m" :for="name" v-html="label">
      </label>
    </div>
    <div class="invalid-feedback" :id="`${name}invalid`">{{field.missing_error}}</div>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    type: String,
    name: String,
    value: Number,
    field: Object
  },
  methods: {
    offValue() {
      if (this.field.boolean) return "false";
      return "0";
    },
    onValue() {
      if (this.field.boolean) return "true";
      return this.value;
    }
  }
  
}
</script>