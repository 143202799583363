<template>
    <bdi class="text-nowrap" dir="ltr">₪ {{roundedValue}}</bdi>
</template>
<script>
export default {
    props : ['value'],
    computed: {
        roundedValue() {
            const strVal = '' + this.value;
            return strVal.replace('.00','');
        }
    }
}
</script>