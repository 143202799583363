<template>
  <div class="form-group form-group-border-bottom align-items-center f14-l18" dir="rtl">
      <input :type="type" class="form-control-plaintext" list="datalistOptions" :id="name" :aria-describedby="`fieldDescription_` + name" :name="name" :value="value">
      <div class="invalid-feedback show" :id="`${name}invalid`"></div>
      <datalist id="datalistOptions" dir="rtl" style="text-align:right;">
        <option v-for="opt in datalist" :value="opt" :key="opt" ></option>
      </datalist>
      <!-- <small :id="`fieldDescription_` + name" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    type: String,
    name: String,
    value: String,
    datalist: Array
  }
}
</script>