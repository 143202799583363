<template>
  <div class="text-center">
    <div class="btn-group row gx-2 gy-2" data-toggle="buttons" role="group">
      <input type="hidden" ref="form" :name="field.data_name" v-model="checklist">
      <div v-for="(line, lidx) in getLineOfOptions()" :key="`line-${lidx}`" class="row gy-2">
        <div v-if="line.length==2" class="col-2">&nbsp;</div>
        <div v-for="(option,idx) in line" :key="`option-${lidx}-${idx}`" class="col-4">
          <input type="checkbox" class="btn-check" :id="`item_`+name+`_`+runningIndex(lidx, idx)" autocomplete="off" :value="option.val" v-model="checklist">
          <label class="btn btn1 text-center d-flex flex-column f14-l18" :for="`item_`+name+`_`+runningIndex(lidx, idx)" style="height: 80px;">
            <SvgImage :src="option.icon" style="height:30px;" class="m-auto" />
            {{ option.text }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SvgImage from "@/components/generic/SvgImage"
export default {
  components: {
    SvgImage
  },
  data() {
    return {
      checklist: []
    }
  },
  props: {
    label: String,
    type: String,
    name: String,
    options: Array,
    field: Object
  },
  methods: {
    getLineOfOptions() {
      var lines = [], size = 3;
      for (let i = 0; i < this.options.length; i += size)
        lines.push(this.options.slice(i, i + size));
      // console.log(lines);
      return lines
    },
    runningIndex(lidx, oidx) {
      return lidx * 3 + oidx;
    }
  }
}
</script>
<style scoped>
.btn1 {
  border: 1px solid #bbbaba;
  border-radius: 20px;
  background: white;
  margin: 0 5px;
  display: inline-block;
  text-align: center;
  color: #171717;
  text-decoration: none;
}

.btn-check:checked + .btn1 {
  color: #ef3d49;
  border-color: #ef3d49;
  background-color: #f9f9f9;
}
</style>