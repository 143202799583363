<template>
  <div class="d-flex flex-row justify-content-center align-items-center">
    <input type="hidden" :name="field.data_name" v-model="value" ignore0="true" />
    <div v-if="field.label" class="text-right flex-fill f16-l23-m align-self-end" v-html="field.label"></div>
    <div class="d-flex flex-column nowrap" dir="rtl">
      <div v-if="field.numbers" class="d-flex flex-row nowrap f16-l23-m" dir="rtl">
        <div class="pointer padding-5" v-for="item in 5" :key="item" @click="selectOption(item)">
          <span class="text-center" style="display:inline-block;width:20px;height:20px">{{item}}</span>
        </div>
      </div>
      <div class="d-flex flex-row nowrap" dir="rtl">
        <div class="pointer padding-5" v-for="item in 5" :key="item" @click="selectOption(item)" :class="getClass(item)">
          <img src="@/assets/rating-star-red.svg" style="width:20px;height:20px" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    field: Object,
  },
  data() {
    return {
      value: 0
    }
  },
  methods: {
    selectOption(item) {
      this.value = item;
      this.$emit("ratingChanged", this.value);
    },
    getClass(item) {
      if (item > this.value) {
        return "grayed";
      }
    }
  }
}
</script>
<style scoped>
.grayed {
  opacity: 0.3;
}
</style>