<template>
  <img src="@/assets/loader.gif" :class="`loading-size loading-image-` + clz" />
  <!-- <span class="loading-indicator spinner" role="status" v-if="loading" :class="['sz-' + size]">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </span> -->
</template>
<script>
export default {
  name: "loading-indicator",
  props: {
    loading: Boolean,
    size: String,
    clz: String
  }
};
</script>
<style>
/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */

.loading-size {
  height: 144px;
  width: 171.6px;
}

.loading-image-center {
  transform: translate(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

/*
.loading-image-undefined {
}
*/
.loading-image-overflow {
  position: absolute;
  top: 100px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 20000;
}

.loading-indicator.spinner {
  width: 70px;
  text-align: center;
}

.loading-indicator.spinner.sz-small > div {
  width: 4px;
  height: 4px;
}

.loading-indicator.spinner > div {
  width: 8px;
  height: 8px;
  background-color: #ef3d49;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loading-indicator.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loading-indicator.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>