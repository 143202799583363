<template>
  <div class="grey-bg margin-top-10 padding-tb-10">
    <div class="d-flex flex-column text-center">
      <div class="f18-l20-b">{{title}}</div>
      <div class="f14-l18" v-html="mainText" />
      <div class="f18-l20-b margin-top-10" v-html="addText" />
      <a class="f14-l18 red-link margin-top-10" :href="url">{{urlLabel}}</a>
    </div>
  </div>

</template>
<script>
export default {
  props: ['title', 'mainText', 'addText', 'urlLabel', 'url']
}
</script>
