<template>
  <div>
    <form v-if="field.method=='POST'" :action="targetHref" :method="field.method">
      <button type="submit" class="btn-as-link">{{field.label.text}}</button>
    </form>
    <a v-else class="black-link" :href="field.href">{{field.label.text}}</a>
  </div>
</template>
<script>
import {baseUrl} from '@/modules/configuration.js'
export default {
  props: {
    field: Object,
  },
  computed: {
    targetHref() {
      return baseUrl(this.$props.field.href);
    }
  }
}
</script>