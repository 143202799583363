import {createRouter, createWebHistory} from 'vue-router'
import SiteView from '../views/SiteView.vue'

const routes = [
  {
    path: '/',
    name: 'site',
    component: SiteView,
    children: [{
      path: '',
      name: 'home',
      component: () => import('../views/HomeView.vue')
    },
    {
      path: 'c/:cookid',
      name: 'cook',
      component: () => import('../views/CookView.vue'),
      children: [{
        path: 'd/:dishid',
        name: 'dish',
        component: () => import('../views/DishView.vue')
      }
      ]
    },
    {
      path: 's/:url',
      name: 's-url',
      component: () => import('../views/system/ShortUrlView.vue')
    },
    {
      path: 'short/:url',
      name: 'short-url',
      component: () => import('../views/system/ShortUrlView.vue')
    },
    {
      path: 'r/:pathMatch(.*)*',
      name: 'r-url',
      component: () => import('../views/system/RedirectUrlView.vue')
    },
    {
      path: 'search/cooks',
      name: 'search-cooks',
      component: () => import('../views/SearchView.vue')
    },
    {
      path: 'search/:ptype',
      name: 'search-ptype',
      component: () => import('../views/SearchView.vue')
    },
    {
      path: 'search/:ptype/:prefid',
      name: 'search-cpref',
      component: () => import('../views/SearchView.vue')
    },
    {
      path: 'search/:pathMatch(.*)*',
      name: 'search-anysearcg',
      component: () => import('../views/SearchView.vue')
    },
    // {
    //   path: '/search/:ptype/:prefid',
    //   name: 'search-preference',
    //   component: () => import('../views/SearchCPrefView.vue')
    // },
    {
      path: 'contact',
      name: 'contact',
      component: () => import('../views/general/ContactView.vue')
    },
    {
      path: 'quality_standard',
      name: 'quality_standard',
      component: () => import('../views/general/QualityStandardView.vue')
    },
    {
      path: 'about_orders',
      name: 'about_orders',
      component: () => import('../views/general/AboutOrdersView.vue')
    },
    {
      path: 'static',
      alias: ['/tos', '/stories', '/specials', '/intro', '/cook_to_home'],
      name: 'static',
      component: () => import('../views/StaticView.vue')
    },
    {
      path: 'static/:uemail',
      alias: [ '/remove_delivery_alert/:uemail', '/rnor/:uemail'],
      name: 'static_with_param',
      component: () => import('../views/StaticView.vue')
    },
    {
      path: '/pages/:page',
      name: 'static-pages',
      component: () => import('../views/StaticView.vue')
    },
    {
      path: 'about',
      name: 'about',
      component: () => import('../views/general/AboutView.vue')
    },
    {
      path: 'ambassadors',
      name: 'ambassadors',
      component: () => import('../views/general/AmbassadorsView.vue')
    },
    {
      path: 'impact',
      name: 'impact',
      component: () => import('../views/general/ImpactView.vue')
    },
    // {
    //   path: 'catering',
    //   name: 'catering',
    //   component: () => import('../views/old/CateringView.vue')
    // },
    {
      path: 'cook_join',
      name: 'cook_join',
      component: () => import('../views/general/CookJoinView.vue')
    },
    {
      path: 'cook_join_completed',
      name: 'cook_join_completed',
      component: () => import('../views/general/CookJoinCompletedView.vue')
    },
    {
      path: 'faq',
      name: 'faq',
      component: () => import('../views/general/FaqView.vue')
    },
    {
      path: 'health_faq',
      name: 'health_faq',
      component: () => import('../views/general/HealthFaqView.vue')
    },
    {
      path: 'events',
      name: 'events',
      component: () => import('../views/general/EventsView.vue')
    },
    {
      path: 'offices',
      name: 'offices',
      component: () => import('../views/general/OfficesView.vue')
    },
    {
      path: 'newsletter',
      name: 'newsletter',
      component: () => import('../views/NewsLetterView.vue')
    },
    {
      path: 'users/login',
      name: 'users-login',
      component: () => import('../views/user/UserLoginView.vue')
    },
    {
      path: 'users/register',
      name: 'users-register',
      component: () => import('../views/user/UserRegisterView.vue')
    },
    {
      path: 'users/recover_password',
      name: 'users-recover-password',
      component: () => import('../views/user/UserRecoverPasswordView.vue')
    },
    {
      path: 'users/change_password',
      name: 'users-change-password',
      component: () => import('../views/user/UserChangePasswordView.vue')
    },
    {
      path: 'users/settings',
      name: 'users-settings',
      component: () => import('../views/user/UserSettingsView.vue')
    },
    {
      path: '/users/select_city',
      name: 'users-select-city',
      component: () => import('../views/popup/SelectCityPopup.vue')
    },
    {
      path: 'favorites',
      name: 'favorites',
      // component: () => import(      '../views/SearchView.vue')
      component: () => import('../views/user/FavoritesView.vue')
    },
    {
      path: 'orders',
      name: 'orders',
      component: () => import('../views/user/OrdersView.vue')
    },
    ///
    {
      path: 'orders/:orderId/review',
      name: 'orders-review',
      component: () => import('../views/user/OrdersReviewView.vue'),
      // meta: {fullscreen: true}
    },
    {
      path: '/orders/checkout',
      name: 'orders-checkout',
      component: () => import('../views/user/OrdersCheckoutView.vue'),
      // meta: {fullscreen: true}
    },
    {
      path: '/orders/payment',
      name: 'orders-payment',
      component: () => import('../views/user/OrdersPayment.vue'),
      // meta: {fullscreen: true}
    },
    {
      path: '/orders/meshulam_gift_coupon_return',
      name: 'orders-meshulam_gift_coupon_return',
      component: () => import('../views/user/OrdersSendToServerAndRedirectView.vue'),
      meta: {fullscreen: true}
    },
    {
      path: '/orders/meshulam_order_payment',
      name: 'orders-meshulam_order_payment',
      component: () => import('../views/user/OrdersSendToServerAndRedirectView.vue'),
      meta: {fullscreen: true}
    },
    {
      path: '/orders/donation_return',
      name: 'orders-donation_return',
      component: () => import('../views/user/OrdersSendToServerAndRedirectView.vue'),
      meta: {fullscreen: true}
    },
    {
      path: '/orders/donation_paypal_return',
      name: 'orders-donation_paypal_return',
      component: () => import('../views/user/OrdersSendToServerAndRedirectView.vue'),
      meta: {fullscreen: true}
    },
    {
      path: '/orders/donation_paypal_cancel',
      name: 'orders-donation_paypal_cancel',
      component: () => import('../views/user/OrdersSendToServerAndRedirectView.vue'),
      meta: {fullscreen: true}
    },
    {
      path: '/orders/paypal_success',
      name: 'orders-paypal_success',
      component: () => import('../views/user/OrdersSendToServerAndRedirectView.vue'),
      meta: {fullscreen: true}
    },
    {
      path: '/orders/paypal_cancel',
      name: 'orders-paypal_cancel',
      component: () => import('../views/user/OrdersSendToServerAndRedirectView.vue'),
      meta: {fullscreen: true}
    },
    {
      path: '/orders/gift_coupon_completed',
      name: 'orders-gift-coupon-completed',
      component: () => import('../views/user/OrdersGiftCouponCompletedView.vue'),
      // meta: {fullscreen: true}
    },
    {
      path: '/orders/:orderId/completed',
      name: 'orders-completed',
      component: () => import('../views/user/OrdersCompletedView.vue'),
      // meta: {fullscreen: true}
    },
    {
      path: '/orders/paypal_gift_coupon_success',
      name: 'orders-paypal-gift-coupon-success',
      component: () => import('../views/user/OrdersSendToServerAndRedirectView.vue'),
      // meta: {fullscreen: true}
    },
    {
      path: '/orders/paypal_gift_coupon_cancel',
      name: 'orders-paypal-gift-coupon-cancel',
      component: () => import('../views/user/OrdersSendToServerAndRedirectView.vue'),
      // meta: {fullscreen: true}
    },
    {
      path: '/orders/proceed_checkout',
      name: 'orders-proceed-checkout',
      component: () => import('../views/user/OrdersProceedCheckoutView.vue'),
      // meta: {fullscreen: true}
    },

    {
      path: '/orders/delivery_details',
      name: 'orders-delivery-details',
      component: () => import('../views/user/OrdersDeliveryDetailsView.vue'),
      // meta: {fullscreen: true}
    },
    {
      path: '/orders/gift_coupon_checkout',
      name: 'gift-coupon-checkout',
      component: () => import('../views/general/GiftCouponView.vue')
    },
    {
      path: '/donation',
      name: 'donation',
      component: () => import('../views/general/DonationView.vue')
    },
    {
      path: '/donation_with_walla',
      name: 'donation-with-walla',
      component: () => import('../views/general/DonationWithWallaView.vue')
    },
    {
      path: '/orders/donation_form',
      name: 'donation_form',
      component: () => import('../views/general/DonationFormView.vue')
    },
    {
      path: '/orders/donation_form_en',
      name: 'donation_form_en',
      component: () => import('../views/general/DonationFormEnView.vue')
    },
    {
      path: '/orders/donation_completed',
      name: 'donation_completed',
      component: () => import('../views/user/DonationCompletedView.vue')
    },
    {
      path: '/team',
      name: 'team',
      component: () => import('../views/general/TeamView.vue')
    },
    {
      path: '/cooks_site',
      name: 'cooks_site',
      component: () => import('../views/general/CooksSiteView.vue')
    },
    {
      path: '/media',
      name: 'media',
      component: () => import('../views/general/FromTheMediaView.vue')
    },
    {
      path: "/test",
      name: "test",
      component: () => import('../views/system/TestPageNotFoundView.vue')
    },
    {
      path: "/not_found",
      name: "not_found",
      component: () => import('../views/system/PageNotFoundView.vue')
    },
    {
      path: "/e500",
      name: "e500",
      component: () => import('../views/system/PageErrorView.vue')
    },
    {
      path: "/:catchAll(.*)",
      component: () => import('../views/system/PageNotFoundView.vue')
    }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
