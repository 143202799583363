<template>
  <div v-if="data?.toolbar" class="desktop-toolbar-spacesaver position-relative">
    <nav class="navbar navbar-expand-xl bg-white desktop-navbar-fixed-top" style="min-height:61px">
      <div class="container-fluid mw1180 position-relative">

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainmenu" aria-controls="mainmenu" aria-expanded="false" aria-label="Toggle navigation">
          <!-- <span class="navbar-toggler-icon"></span> -->
          <img :src="menuIcon" alt="menu" class="toolbar-icon" />
        </button>
        <button type="button" class="btn dropdown-toggle" id="userDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="df20-l20" v-if="$isDesktop()" v-html="$responsive(data.toolbar).menu.user.greetings.text_html"></span>
          <SvgImage :src="$responsive(data.toolbar).menu.user.icon" />
        </button>
        <ul class="dropdown-menu" aria-labelledby="userDropdownMenu" dir="rtl">
          <li v-for="(item, idx) in $responsive(data.toolbar).menu.user.menu" :key="idx" style="text-align:right;">
            <a v-if="item.href && item.href!='/users/logout'" class="dropdown-item f20-l20" :href="item.href">{{item.text}}</a>
            <span v-if="item.href=='/users/logout'" class="dropdown-item f20-l20" @click="serverFetch(item.href)">{{item.text}}</span>
            <span v-if="item.text_html" class="dropdown-item f20-l20" v-html="item.text_html"></span>
          </li>
        </ul>
        <button type="button" class="btn" @click="searchPopup()"><img src="@/assets/icons/header-search.svg" /></button>
        <div class="position-relative pointer" @click="clickCart">
          <SvgImage :src="$responsive(data.toolbar).cart.icon" />
          <span v-if="cartQuantity>0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-red">
            {{cartQuantity}}
            <span class="visually-hidden">unread messages</span>
          </span>
        </div>
        <span class="flex-grow-1"></span>
        <router-link to="/" class="navbar-brand white-link mobile-or-tablet"><img src="@/assets/logo.png" /></router-link>
        <div class="collapse navbar-collapse" id="mainmenu" dir="rtl">
          <ul class="navbar-nav me-auto w-100 mb-2 mb-lg-0">
            <li class="nav-item text-right bottom-splitter" v-for="(item, idx) in $responsive(data.toolbar).menu.main" :key="idx">
              <a v-if="item.href" class="nav-link f20-l20 black-link" :href="item.href">{{ item.text }}</a>
              <span v-if="item.text_html" class="dropdown-item f20-l20" v-html="item.text_html"></span>
            </li>
          </ul>
        </div>
        <router-link to="/" class="navbar-brand white-link desktop-only" style="margin-right:0;"><img src="@/assets/logo.png" /></router-link>
      </div>
      <HorizontalSplitter class="show position-absolute" id="toolbarBottomSplitter" style="bottom:1px;" />
    </nav>
    <PopupComponent :showClose="false" v-if="showPopup" @close="closePopup">
      <SearchPopupComponent @close="closePopup" :forceSearch="true" />
    </PopupComponent>
  </div>
</template>
<script>
/* eslint-disable */
/*global bootstrap*/
import {mapGetters} from "vuex";
import {fetch2} from "@/modules/configuration.js";
import headerMobileMenu from "@/assets/icons/header-mobile-menu.svg";
import HorizontalSplitter from "@/components/generic/HorizontalSplitter.vue"
import SvgImage from "@/components/generic/SvgImage";
import PopupComponent from '@/components/PopupComponent.vue';
import SearchPopupComponent from '@/components/popup/SearchPopupComponent.vue';
export default {
  components: {
    HorizontalSplitter,
    SvgImage,
    PopupComponent,
    SearchPopupComponent
  },
  props: {
    initialData: {},
  },
  data() {
    return {
      menuIcon: headerMobileMenu,
      showPopup: false,
      data: {}
    }
  },
  mounted() {
    this.data = this.initialData;
    this.$store.dispatch("fetchCartQuantityData");
  },
  computed: {
    ...mapGetters([
      'cartQuantity'
    ])
  },
  methods: {
    async serverFetch(url) {
      const data = await fetch2(this, url);
      this.data = data;
    },
    openPopup(url) {
      this.$store.dispatch('showPopupWithUrl', url)
    },
    toggleMenu() {
      let myCollapse = document.getElementById('navbarNav');
      var bsCollapse = new bootstrap.Collapse(myCollapse, {toggle: false});
      bsCollapse.toggle();
      let bottomSplitter = document.getElementById('toolbarBottomSplitter');
      let bsBottomSplitter = new bootstrap.Collapse(bottomSplitter, {toggle: true});
      bsBottomSplitter.toggle();
    },
    searchPopup() {
      this.showPopup = !this.showPopup;
    },
    closePopup(refresh) {
      this.showPopup = false;
      if (refresh) {
        this.$router.go(0);
      }
    },
    clickCart() {
      // const cartElement = this.$responsive(this.data.toolbar).cart;
      // if (cartElement.popup) {
      //   this.openPopup(cartElement.href);
      // } else {
      //   this.$router.push(cartElement.href);
      // }

      if (this.$isDesktop()) {
        this.openPopup('/orders/cart_panel')
      } else {
        this.$router.push('/orders/checkout');
      }
    }
  }

}
</script>
<style scoped>
.toolbar {
  height: 60px;
  line-height: 60px;
  background-color: white;
  padding-top: 9px;
  padding-bottom: 9px;
}
.toolbar .logo {
  height: 42px;
}
.toolbar .spacer-left {
  margin-left: 20px;
}
.toolbar .toolbar-icon {
  widows: 25px;
  height: 18px;
}
</style>