<template>
  <div v-if="!loading" dir="rtl">
    <div class="d-flex flex-row">
      <div class="f20-l30-m " @click="$emit('close')">X</div>
      <div class="f26-l30-m text-center flex-grow-1" v-html="data.header.text_html"></div>
    </div>
    <HorizontalSplitter />
    <form class="formfield-round-border" :action="data.submit.url" ref="form" method="post">
      <div class="f16-l19 margin-top-20" v-html="data.body.text_html"></div>
      <FormAutoCompleteFieldComponent type="text" :name="data.body.fields[0].data_name" :datalist="data.body.fields[0].options" />
      <ButtonComponent :caption="data.submit.label" clazz="margin-top-20" @click="onSubmit()" @keyup.enter="onSubmit()"></ButtonComponent>
      <div class="justify-content-center text-center margin-top-20 f14-l18">
        <a class="red-link" href="#" @click="$emit('close')">{{data.buttons[0].label}}</a>
      </div>
    </form>
  </div>
</template>
<script>
import {fetch2, formSubmit} from '@/modules/configuration.js'
import HorizontalSplitter from "@/components/generic/HorizontalSplitter.vue"
import ButtonComponent from "@/components/form/ButtonComponent"
import FormAutoCompleteFieldComponent from "@/components/form/FormAutoCompleteFieldComponent"

export default {
  components: {
    HorizontalSplitter,
    ButtonComponent,
    FormAutoCompleteFieldComponent
  },
  data() {
    return {
      loading: true,
      data: {}
    }
  },
  methods: {
    async getData(url) {
      const data = await fetch2(this, url);
      this.data = data;
      this.loading = false;

    },
    async onSubmit() {
      let form = this.$refs.form;
      /*let response =*/ await formSubmit(this, form);
      await this.$nextTick();
      this.$emit("close");
      if (this.$route.path.indexOf('/d/') > 0 || this.$route.path.indexOf('/c/') === 0  /*dish/deal page / cook page*/) {
        this.emitter.emit('city-selected');
      } else {
        this.$router.go(0);
      }
    }
  },
  mounted() {
    this.getData("users/select_city");
  },

}
</script>