<template>
  <!-- <hr :class="'splitter-'+type" /> -->
  <div :class="'splitter-' + (type || 'line')" />
</template>
<script>
export default {
  props: {
    type: String,
    default() {
      return "line";
    }
  }
}
</script>
<style scoped>
.splitter-line {
  width: 100%;
  height: 5px;
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #0000001E;
}

.splitter-dashed {
  width: 100%;
  height: 5px;
  background-image: linear-gradient(to right, #ccc 40%, #ffff 20%);
  background-position: top;
  background-size: 20px 1px;
  background-repeat: repeat-x;
}

.splitter-red_bold {
  width: 100%;
  height: 5px;
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 0px;
  border-bottom: 5px solid red;
}
</style>